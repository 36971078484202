import React from 'react'
import HeadingSlice from '../Slices/HeadingSlice'
import LargeImageSlice from '../Slices/LargeImageSlice'
import TwoImageSlice from '../Slices/TwoImageSlice'
import ContentSlice from '../Slices/ContentSlice'
import ImageContentSlice from '../Slices/ImageContentSlice'
import LinkPanelSlice from '../Slices/LinkPanelSlice'
import LocationsSlice from '../Slices/LocationsSlice'
import AppInformationSlice from '../Slices/AppInformationSlice'
import AccordionSlice from '../Slices/AccordionSlice'
import FacilitiesCardsSlice from '../Slices/FacilitiesCardsSlice'
import OffersCardsSlice from '../Slices/OffersCardsSlice'
import PathwaysSlice from '../Slices/PathwaysSlice'
import RoomsSlice from '../Slices/RoomsSlice'
import GallerySlice from '../Slices/GallerySlice'
import Reviews from '../Reviews'

const SlicesArea = ({ slicesData }) => {
  return (
    <main className="slices">
      {slicesData &&
        slicesData.map((item, index) => {
          switch (item.slice_type) {
            case 'heading':
              return <HeadingSlice index={index} key={index} sliceData={item} />
            case 'content':
              return <ContentSlice key={index} sliceData={item} />
            case 'large_image':
              return <LargeImageSlice key={index} sliceData={item} />
            case 'two_image':
              return <TwoImageSlice key={index} sliceData={item} />
            case 'facilities_cards':
              return <FacilitiesCardsSlice key={index} sliceData={item} />
            case 'offers':
              return <OffersCardsSlice key={index} sliceData={item} />
            case 'pathways':
              return <PathwaysSlice key={index} sliceData={item} />
            case 'rooms':
              return <RoomsSlice key={index} sliceData={item} />
            case 'gallery':
              return <GallerySlice key={index} sliceData={item} />
            case 'image___content':
              return (
                <ImageContentSlice index={index} key={index} sliceData={item} />
              )
            case 'link_panel':
              return <LinkPanelSlice key={index} sliceData={item} />
            case 'locations':
              return <LocationsSlice key={index} sliceData={item} />
            case 'app_information':
              return (
                <AppInformationSlice
                  index={index}
                  key={index}
                  sliceData={item}
                />
              )
            case 'accordion':
              return <AccordionSlice key={index} sliceData={item} />
            case 'reviews':
              return <Reviews key={index} />
            default:
              return null
          }
        })}
    </main>
  )
}

export default SlicesArea
