import React, { useEffect, useRef } from 'react'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import Image from 'components/Image'
import { useModal } from '../../context/ModalContext'

const Slider = ({ images, rooms, roomSlider = false }) => {
  const swiperRef = useRef(null)
  const nextRef = useRef(null)
  const prevRef = useRef(null)
  const { openModal } = useModal()

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        loop: false,
        slidesPerView: roomSlider ? 3.5 : 1,
        navigation: {
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        },
        spaceBetween: 20,
        breakpoints: roomSlider
          ? {
              320: { slidesPerView: 1 },
              480: { slidesPerView: 1.25 },
              768: { slidesPerView: 2.25 },
              1024: { slidesPerView: 3 },
              1280: { slidesPerView: 3.5 },
            }
          : {},
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      })
    }
  }, [])

  return (
    <div className="swiper-container" ref={swiperRef}>
      {roomSlider
        ? rooms?.length > 0 && (
            <div className="swiper-wrapper">
              {rooms.map(({ data }, index) => (
                <div className="swiper-slide" key={index}>
                  <div className="room__slide">
                    <Image
                      fluid={data.image2.localFile?.childImageSharp?.fluid}
                    />
                    <div className="room__content ">
                      <div className="room__content_wrap">
                        <h2 className="room__heading">{data.title}</h2>
                        <div className="room__icons">
                          <div className="room__icon">
                            <ManIcon />
                            <p>{data.people.text}</p>
                          </div>
                          <div className="room__icon">
                            <BedIcon />
                            <p>{data.beds.text}</p>
                          </div>
                        </div>
                        <p className="truncates">{data.summary}</p>
                      </div>
                      <div className="room__buttons">
                        <button
                          className="button-primary"
                          onClick={() =>
                            openModal(ModalContent, {
                              images: data.images,
                              content: data.popup,
                              title: data.title,
                              beds: data.beds.text,
                              people: data.people.text,
                            })
                          }
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        : images?.length > 0 && (
            <div className="swiper-wrapper">
              {images.map((image, index) => (
                <div className="swiper-slide" key={index}>
                  <Image
                    fluid={image.localFile?.childImageSharp?.fluid}
                    src={image?.image?.url}
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
          )}

      {/* Custom Navigation Buttons */}
      {!roomSlider && (
        <>
          <button className="custom-swiper-button-prev" ref={prevRef}>
            <SvgIcon />
          </button>
          <button className="custom-swiper-button-next" ref={nextRef}>
            <SvgIcon />
          </button>
        </>
      )}
      {roomSlider && (
        <div className="rooms__slide_buttons">
          <button className="custom-swiper-button-prev" ref={prevRef}>
            <SvgIcon />
          </button>
          <button className="custom-swiper-button-next" ref={nextRef}>
            <SvgIcon />
          </button>
        </div>
      )}

      {!roomSlider && (
        <div className="swiper-pagination custom-swiper-pagination"></div>
      )}
    </div>
  )
}

const ModalContent = ({ images, content, title, people, beds }) => (
  <div className="room__popup">
    <div className="room__popup_slider">
      <Slider images={images} />
    </div>
    <div className="room__popup_wrap">
      <div className="room__popup_upper">
        <h2 className="room__heading">{title}</h2>
        <div className="room__icons">
          <div className="room__icon">
            <ManIcon />
            <p>{people}</p>
          </div>
          <div className="room__icon">
            <BedIcon />
            <p>{beds}</p>
          </div>
        </div>
        <div
          className="room__popup_content"
          dangerouslySetInnerHTML={{
            __html: content.html,
          }}
        />
      </div>
    </div>
  </div>
)

const SvgIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 51">
    <path stroke="#fff" strokeWidth="2.5" d="m20.25 12.812 12 12-12 12"></path>
    <circle
      cx="25"
      cy="25.811"
      r="24"
      stroke="#11B9BC"
      strokeWidth="2"
    ></circle>
  </svg>
)

const ManIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 36">
    <path
      fill="#11B9BC"
      d="M20.753 9.25c0-1.45-.565-2.841-1.57-3.867a5.3 5.3 0 0 0-3.787-1.602 5.3 5.3 0 0 0-3.789 1.602 5.53 5.53 0 0 0-1.569 3.867c0 1.45.565 2.841 1.57 3.867a5.3 5.3 0 0 0 3.787 1.602 5.3 5.3 0 0 0 3.789-1.602 5.53 5.53 0 0 0 1.569-3.867m-13.929 0c0-2.32.903-4.546 2.51-6.187A8.48 8.48 0 0 1 15.397.5c2.273 0 4.453.922 6.06 2.563a8.84 8.84 0 0 1 2.51 6.187c0 2.32-.902 4.546-2.51 6.187A8.48 8.48 0 0 1 15.396 18a8.48 8.48 0 0 1-6.061-2.563 8.84 8.84 0 0 1-2.51-6.187ZM3.697 32.219H27.1c-.596-4.327-4.239-7.657-8.639-7.657h-6.12c-4.4 0-8.042 3.33-8.638 7.657zm-3.301 1.25c0-6.733 5.343-12.188 11.94-12.188h6.12c6.596 0 11.94 5.455 11.94 12.189 0 1.12-.891 2.03-1.99 2.03H2.385c-1.098 0-1.988-.91-1.988-2.03Z"
    ></path>
  </svg>
)

const BedIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 31">
    <path
      fill="#11B9BC"
      d="M2.15 30.045h1.045c.913 0 1.51-.578 1.51-1.49v-2.648c.205.056.801.093 1.23.093h34.393c.429 0 1.007-.037 1.212-.093v2.647c0 .914.596 1.491 1.51 1.491h1.062c.914 0 1.51-.578 1.51-1.49V16.4c0-3.188-1.622-4.977-4.623-5.201V5.085c0-3.3-1.752-4.978-4.958-4.978H10.204c-3.188 0-4.959 1.678-4.959 4.978v6.114c-3.001.224-4.623 2.013-4.623 5.2v12.155c0 .914.597 1.491 1.529 1.491ZM9.925 7.881c0-1.64.914-2.554 2.591-2.554h6.245c1.66 0 2.591.913 2.591 2.554v3.3H9.924v-3.3Zm14.988 0c0-1.64.913-2.554 2.59-2.554h6.227c1.678 0 2.61.913 2.61 2.554v3.3H24.912v-3.3ZM4.35 23.279c-.373 0-.615-.261-.615-.69V16.12c0-1.36.895-2.218 2.274-2.218h34.245c1.36 0 2.255.857 2.255 2.218v6.469c0 .429-.242.69-.597.69z"
    ></path>
  </svg>
)

export default Slider
