import React from 'react'
import ContentHeading from '../Content/ContentHeading'

const HeadingSlice = ({ index, sliceData }) => (
  <ContentHeading
    index={index}
    heading={sliceData.primary.heading}
    text={sliceData.primary.text}
  />
)

export default HeadingSlice
