import React from 'react'
import Image from 'components/Image'
import Link from '../Link'
import { useNavigationItem } from '../../utils/navigation'

// Create a separate component for each pathway card.
const PathwayCard = ({ name, image, text, link }) => {
  // Call the hook at the top level of the component.
  const newLink = useNavigationItem(link.uid)

  return (
    <div key={link.uid} className="pathways__card">
      <Image src={image.url} />
      <div className="pathways__content">
        <h3>{name.text}</h3>
        <p>{text.text}</p>
        <div className="pathways__buttons">
          <Link className="button-secondary" link={{ url: newLink }}>
            More Info
          </Link>
        </div>
      </div>
    </div>
  )
}

const PathwaysSlice = ({ sliceData }) => {
  return (
    <section className="my-6 md:my-10 lg:my-12 xl:my-18">
      <div className="container">
        <h3 className="pathways__heading">{sliceData.primary.title.text}</h3>
        <div className="pathways">
          {sliceData.items.map(({ name, image, text, link }) => (
            <PathwayCard
              key={link}
              name={name}
              image={image}
              text={text}
              link={link}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default PathwaysSlice
