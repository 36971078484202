import React from 'react'
import ContentExcerpt from '../Content/ContentExcerpt'
import Image from 'components/Image'

const ContentWithImage = ({
  heading,
  text,
  button,
  buttonText,
  buttonColour,
  image,
  callOut,
  layout,
}) => (
  <section className="contimg  my-5 md:my-8">
    <div
      className={`contimg__wrapper container relative z-10 ${
        layout === 'alternative' ? 'contimg--left' : ''
      }`}
    >
      <div className={``}>
        <div className={`w-full py-10 md:py-22 `}>
          <div className="md:w-10/12">
            <ContentExcerpt
              heading={heading}
              text={text}
              button={button}
              buttonText={buttonText}
              buttonColour={buttonColour}
            />
          </div>
        </div>
        {callOut && (
          <div
            className={`w-1/2 pl-8 pt-10 md:pt-20 ${
              layout === 'alternative' ? '' : 'hidden lg:flex justify-end'
            }`}
          >
            <div className="md:w-10/12">
              <div className="w-56 h-56 xl:w-75 xl:h-75 flex items-center justify-center text-center rounded-full bg-peach-opaque-90 p-8 xl:p-12">
                <h3 className="text-2xl xl:text-3xl text-white font-semibold leading-tight">
                  {callOut}
                </h3>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`contimg__image `}>
        {image && (image.localFile || image.url) && (
          <Image
            className="relative w-full lg:h-full z-10"
            src={image.url}
            fluid={image.localFile?.childImageSharp?.fluid}
            alt={image.alt}
          />
        )}
      </div>
      {callOut && (
        <div className="bg-peach text-center py-3 px-5 lg:hidden">
          <h3 className="text-sm md:text-xl text-white font-semibold">
            {callOut}
          </h3>
        </div>
      )}
    </div>
  </section>
)

export default ContentWithImage
