import React from 'react'
import Image from 'components/Image'

const FacilitiesCardsSlice = ({ sliceData }) => {
  return (
    <section className="my-6 md:my-10 lg:my-12 xl:my-18">
      <div className="container">
        <div className="facilities">
          {sliceData.items.map(({ name, image, text }) => (
            <div className="facilities__card">
              <Image src={image.url} />
              <h3>{name.text}</h3>
              <p>{text.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FacilitiesCardsSlice
