import React from 'react'
import Image from 'components/Image'
import { useStaticQuery, graphql } from 'gatsby'
import Slider from '../Slider'

const RoomsSlice = ({ sliceData }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicRooms {
        nodes {
          data {
            summary
            title
            beds {
              text
            }
            people {
              text
            }
            images {
              image {
                url
              }
            }
            image2 {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            section_3_image {
              image1 {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            popup {
              html
            }
          }
        }
      }
    }
  `)
  const rooms = data.allPrismicRooms.nodes
  return (
    <section className="my-6 md:my-10 lg:my-12 xl:my-18 slides__wrap">
      <div className="container">
        <div className="slides__upper">
          <h3>{sliceData.main.title1.text}</h3>
          <p>{sliceData.main.text.text}</p>
        </div>
        <div className="slides__left" />
        <div className="slides__right" />
        <div className="slides">
          <Slider rooms={rooms} roomSlider />
        </div>
      </div>
    </section>
  )
}

export default RoomsSlice
