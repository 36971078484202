import React from 'react'
import Image from 'components/Image'
import { Link } from 'gatsby'
import { useModal } from '../../context/ModalContext'

const OffersCardsSlice = ({ sliceData }) => {
  const { openModal } = useModal()
  return (
    <section className="my-6 md:my-10 lg:my-12 xl:my-18">
      <div className="container">
        <div className="offers">
          <h2>{sliceData.primary.title.text}</h2>
          <div className="offers__grid">
            {sliceData.items.map(({ name, image, text, info }) => (
              <div className="offers__card">
                <div className="offers__image">
                  <Image src={image.url} />
                  <div className="offers__image_overlay" />
                </div>
                <div className="offers__content">
                  <h3>{name.text}</h3>
                  <p>{text.text}</p>
                  <div className="offers__buttons">
                    <button
                      className="button-primary px-10"
                      onClick={() =>
                        openModal(ModalContent, {
                          content: info,
                          title: name.text,
                        })
                      }
                    >
                      {'More Info'}
                    </button>
                    <Link
                      className="button-secondary px-10"
                      to={`https://book-directonline.com/properties/mipadhoteldirect`}
                    >
                      {'Book Now'}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const ModalContent = ({ content, title }) => (
  <div className="room__popup offer__popup">
    <div className="room__popup_wrap">
      <div className="room__popup_upper">
        <h2 className="room__heading">{title}</h2>
        <div
          className="room__popup_content"
          dangerouslySetInnerHTML={{
            __html: content.html,
          }}
        />
        <Link
          className="button-secondary px-10"
          to={`https://book-directonline.com/properties/mipadhoteldirect`}
        >
          {'Book Now'}
        </Link>
      </div>
    </div>
  </div>
)

export default OffersCardsSlice
