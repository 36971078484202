import React from 'react'
import Image from 'components/Image'
import Masonry from 'react-masonry-css'

const GallerySlice = ({ sliceData }) => {
  const breakpointColumns = {
    default: 3, // 3 columns on large screens
    1024: 2, // 2 columns on tablets
    600: 1, // 1 column on mobile
  }
  return (
    <section className="gallery-section">
      <div className="container">
        <Masonry
          breakpointCols={breakpointColumns}
          className="masonry-grid"
          columnClassName="masonry-column"
        >
          {sliceData.items.map(({ image }, index) => (
            <div key={index} className="gallery-item">
              <Image
                className="gallery-image"
                src={`${image.url}&w=500&auto=compress`}
                alt={`Gallery Image ${index + 1}`}
              />
            </div>
          ))}
        </Masonry>
      </div>
    </section>
  )
}

export default GallerySlice
